<template>
  <div class="team">
    <div class="header" v-if="us">
      <div class="row">
        <div class="tag q-mb-md" v-if="usinfo.ulevel > 0">{{ usinfo.ulevelName }}</div>
        <div class="tag q-mb-md" v-if="usinfo.xlevel > 0">{{ usinfo.xlevelName }}</div>
        <div class="tag q-mb-md" v-if="usinfo.bdlevel > 0">{{ usinfo.bdlevelName }}</div>
        <div class="tag q-mb-md" v-if="usinfo.hhrlevel > 0">{{ usinfo.hhrlevelName }}</div>
      </div>
      <div class="row items-center">
        {{ $t('TeamCount.tuijianren') }}：
        <div style="text-decoration: underline">{{ abbr(us.reName, 5, 5) }}</div>
      </div>
      <div class="row items-center q-mt-sm">
        <div class="row items-center q-mr-sm">
          {{ $t('TeamCount.lianjie') }}：
          <div class="link">{{ url }}</div>
        </div>
        <q-icon name="content_copy" size="16px" @click="copy(url)"></q-icon>
      </div>
      <div class="row q-mt-sm">
        <div>{{ $t('TeamCount.teamCount') }}：{{ usinfo.teamcount }}</div>
        <div class="q-ml-md "></div>
      </div>
      <div class="row q-mt-sm">
        <div>直推有效人数：{{ usinfo.reCountYouxiao }}</div>
        <div class="q-ml-md ">{{ $t('TeamCount.youxiao') }}：{{ usinfo.teamCountYouxiao }}</div>
      </div>
    </div>

    <div class="card">
      <div class="card-title">{{ $t('TeamCount.liebiao') }}</div>
      <div class="list-scroll">
        <div class="list-val" v-for="item in teamList" :key="item.id">
          <div class="row justify-between">
            <div>{{ $t('Home.address') }}：{{ item.showAddress }}</div>
            <div>{{ $t('Team.jiedianyeji') }}：{{ item.teamyejiNode + item.lskNode }}</div>
          </div>
          <div class="row justify-between">
            <div v-if="item.ulevel > 0">{{ $t('Team.jibie') }}：{{ item.ulevelName }}</div>
            <div v-else>{{ $t('Team.jibie') }}：{{ $t('Team.wu') }}</div>
            <div>{{ $t('Team.aiyeji') }}：{{ item.teamyejiNewRobot + item.lskNewRobot }}</div>
          </div>
        </div>
        <empty :text="$t('Base.empty')" v-if="teamList.length == 0" />
      </div>
    </div>

    <div class="card yeji-card">
      <div class="card-title">{{ $t('TeamCount.tuandui') }}</div>
      <div class="total-row">
        <div class="total-item">
          <div>个人节点理财业绩</div>
          <div class="main-color">{{ usinfo.lskNode }} USDT</div>
        </div>
        <div class="total-item">
          <div>节点理财团队业绩</div>
          <div class="main-color">{{ usinfo.teamyejiNode }} USDT</div>
        </div>
        <div class="total-item">
          <div>个人智能理财业绩</div>
          <div class="main-color">{{ usinfo.lskNewRobot }} USDT</div>
        </div>
        <div class="total-item">
          <div>智能理财团队业绩</div>
          <div class="main-color">{{ usinfo.teamyejiNewRobot }} USDT</div>
        </div>
        <div class="total-item">
          <div>个人质押业绩</div>
          <div class="main-color">{{ usinfo.lskPledge }} USDA</div>
        </div>
        <div class="total-item">
          <div>团队质押业绩</div>
          <div class="main-color">{{ usinfo.teamyejiPledge }} USDA</div>
        </div>
      </div>
    </div>

    <div class="robot-card">
      <div class="total-row" style="margin: 0;">
        <div class="total-item">
          <div>节点理财收益</div>
          <div class="main-color">{{ shouyi.node }} USDA</div>
        </div>
        <div class="total-item">
          <div>IDO收益</div>
          <div class="main-color">{{ shouyi.nodeSong }} CCAI</div>
        </div>
        <div class="total-item">
          <div>智能理财收益</div>
          <div class="main-color">{{ shouyi.newRobot }} USDA</div>
        </div>
        <div class="total-item">
          <div>质押收益</div>
          <div class="main-color">{{ shouyi.pledge }} CCAI</div>
        </div>
      </div>
    </div>

    <tab-bar active="3" ref="tabbarRef"></tab-bar>
  </div>
</template>

<script>
import { ref } from 'vue';
import useClipboard from 'vue-clipboard3';
import TabBar from '@/components/TabBar'

export default {
  components: {
    TabBar
  },
  setup() {
    return {
      us: ref(null),
      usinfo: ref({}),
      url: ref(""),
      teamList: ref([]),
      yeji: ref({}),
      shouyi: ref({})
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    if (this.us) {
      this.url = this.$config.send_url + "#/?reAddress=" + this.us.address
      this.getUser()
      this.getTeam()
      this.getBonusTotal()
    }
  },
  methods: {
    copy(val) {
      let that = this
      const { toClipboard } = useClipboard()
      try {
        toClipboard(val)
        that.$q.dialog({
          message: that.$t('Home.message1')
        })
      } catch (e) {
        console.log(e)
      }
    },
    abbr(val, start, end) {
      if (val.length > 20) {
        let newVal = val.replace(
          val.substring(start, val.length - end),
          "****"
        )
        return newVal
      } else {
        return val
      }
    },
    getUser() {
      let _this = this
      _this.$request.post(
        "api/Users/Get",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              message: res.data.msg,
            });
          }
          let data = res.data.data.us
          _this.usinfo = data
        }
      )
    },
    getTeam() {
      let _this = this
      _this.$request.post(
        "api/Users/GetUserListByReid",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
          reid: _this.us.id
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              message: res.data.msg,
            });
          }
          let data = res.data.data.list
          data.forEach(item => {
            item.showAddress = item.address.replace(
              item.address.substring(
                4,
                item.address.length - 4
              ),
              "****"
            )
          })
          _this.teamList = data
          // console.log(data)
        }
      )
    },
    getBonusTotal() {
      let _this = this
      _this.$request.post(
        "api/Users/GetBonusTotal",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
          uid: _this.us.id,
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              message: res.data.msg,
            });
          }
          let data = res.data.data
          console.log(data)
          _this.shouyi = data
        }
      )
    },
  }
}
</script>

<style scoped>
.team {
  padding: 10px;
  min-height: 100vh;
  background: url('~@/assets/img/base/team-bg.png') no-repeat;
  background-size: 100% auto;
  background-color: #F5F7FA;
}

.header {
  color: #fff;
  padding: 10px;
}

.card {
  margin-top: 15px;
  padding: 40px 10px 10px;
  background: url('~@/assets/img/base/team-bg2.png') no-repeat;
  background-size: 100% 100%;
}

.yeji-card {
  padding: 42px 10px 10px;
  background: url('~@/assets/img/base/team-bg3.png') no-repeat;
  background-size: 100% 100%;
}

.robot-card {
  margin-top: 15px;
  padding: 20px 20px 10px;
  background: url('~@/assets/img/base/team-bg1.png') no-repeat;
  background-size: 100% 100%;
}

.link {
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: underline;
  color: #fff;
}

.card-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
}

.tag {
  margin-right: 15px;
  padding: 1px 15px;
  font-size: 13px;
  border-radius: 4px;
  color: #fff;
  background-color: #2460d7;
}

.list-scroll {
  padding: 0 10px;
  margin-top: 20px;
  height: 160px;
  overflow: auto;
}

.list-val {
  margin-bottom: 10px;
}

.total-row {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}

.total-item {
  padding: 10px 0;
  flex: 50%;
  text-align: center;
  border-left: 1px solid rgba(231, 228, 228, 0.5);
  border-bottom: 1px solid rgba(231, 228, 228, 0.5);
  font-size: 13px;
}

.total-item:nth-child(odd) {
  border-left: none;
}

.total-item:nth-last-child(-n + 2) {
  border-bottom: none;
}
</style>